var $header = $('.search header');

if ($header.length > 0) {
    var $headerHeight = $header.outerHeight();
    var $searchContent = $('.search #content-section .content-wrapper');
    var $offset = $searchContent.offset().top - $headerHeight;
    var $topOffset = $searchContent.offset().top + $headerHeight;
    var $searchContentHeight = $searchContent.outerHeight();
    $(window).scroll(function () {
        if ((window.pageYOffset >= $offset) && (window.pageYOffset <= ($searchContentHeight + $topOffset))) {
            $header.addClass('set-color');
        }
        else {
            $header.removeClass('set-color');
        }

    });
}