var styles = [
    {
        "featureType": "all",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#f5f2eb"
            },
            {
                "saturation": "29"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "saturation": 36
            },
            {
                "color": "#333333"
            },
            {
                "lightness": 40
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#ffffff"
            },
            {
                "lightness": 16
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#fefefe"
            },
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#fefefe"
            },
            {
                "lightness": 17
            },
            {
                "weight": 1.2
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#6b16cc"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#ede5da"
            },
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#f7f3e9"
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#f6edda"
            }
        ]
    },
    {
        "featureType": "landscape.natural.landcover",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#f7f3e9"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f6f2ec"
            },
            {
                "lightness": 21
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f6edda"
            },
            {
                "lightness": 21
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 17
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 29
            },
            {
                "weight": 0.2
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 18
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 16
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f2f2f2"
            },
            {
                "lightness": 19
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#dbcab5"
            },
            {
                "lightness": 17
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#efe8c5"
            }
        ]
    }
];

export function initMap() {
    if (!google || !google.maps) {
        return;
    }

    var maps = document.getElementsByClassName('map');

    for (var i = 0; i < maps.length; i++) {
        var mapContainer = maps[i];
        var locations = maps[i].querySelectorAll('.marker');
        var positions = [];
        for (var j = 0; j < locations.length; j++) {
            var lat = parseFloat(locations[j].getAttribute('data-lat')) || 0;
            var lng = parseFloat(locations[j].getAttribute('data-lng')) || 0;
            var address = locations[j].getAttribute('data-address');
            var title = ' ';
            if(locations[j].getAttribute('data-title')) {
                title = locations[j].getAttribute('data-title');
            }
            positions.push({ lat, lng, address, title });
        }

        if (positions.length < 1) {
            continue;
        }

        var options = {
            center: { lat: positions[0].lat, lng: positions[0].lng },
            zoom: 11,
            styles: styles
        };

        var map = new google.maps.Map(mapContainer, options);
        var bounds = new google.maps.LatLngBounds();

        var marker, position, address;
        for (var j = 0; j < positions.length; j++) {
            position = {
                lat: positions[j].lat,
                lng: positions[j].lng
            };
            address = positions[j].address;
            var labelText = ' ';
            if(positions[j].title != ' ') {
                labelText = positions[j].title;
            }
            marker = new google.maps.Marker({
                position: position,
                map: map,
                icon: {
                    url: "http://maps.google.com/mapfiles/ms/icons/purple-dot.png",
                    labelOrigin: new google.maps.Point(15, 40),
                },
                address: address,
                label: {
                    text: labelText,
                    color: '#6B16CC',
                    
                }
            });
            (function (marker) {
                google.maps.event.addListener(marker, 'click', function() {
                    window.open('https://maps.google.com/?q=' + marker.address, '_blank');
                });
            }) (marker);
   
            if (positions.length > 1) {
                bounds.extend(position);
            }
        }
        if (positions.length > 1) {
            map.fitBounds(bounds);
        }
    }
}