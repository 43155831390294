import Glide from "@glidejs/glide";

function initImagesSlider(id) {
    var imagesGlide = new Glide('#' + id, {
        type: 'carousel',
        startAt: 0,
        perView: 1,
        gap: 0,
        autoplay: 8000,
        animationDuration: 100,
    });
    var images = document.getElementById(id);
    if (images && images.querySelector('.glide__slides').children.length > 1) {
        imagesGlide.mount();
        var glideNavigation = images.querySelector('.glide-navigation');
        var arrowNavigation = document.querySelector('.swipe-navigation');
        var arrowLeft = document.querySelector('.swipe-navigation .navigate-left');
        var arrowRight = document.querySelector('.swipe-navigation .navigate-right');

        if (glideNavigation) {
            imagesGlide.on('run', function (e) {
                var items = glideNavigation.querySelectorAll('a');
                items.forEach(function (item) {
                    item.classList.remove('active');
                });

                items[imagesGlide.index].classList.add('active');
            });

            glideNavigation.querySelectorAll('a').forEach(function (link) {
                link.addEventListener('click', function (e) {
                    e.preventDefault();
                    imagesGlide.go('=' + this.getAttribute('data-id'));
                });
            });
        }
        if (arrowNavigation) {
            arrowLeft.addEventListener('click', function (e) {
                e.preventDefault();
                imagesGlide.go('<');
            });
            arrowRight.addEventListener('click', function (e) {
                e.preventDefault();
                imagesGlide.go('>');
            });
        };
    }

}

window.initImagesSlider = initImagesSlider;