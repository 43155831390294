var $searchIcon = $('header .main-nav .nav-menu #search-icon');
var $searchInput = $('#search #search-input');
var $text = $('body.search #content-section .content-wrapper .search-result a .post-info p');
var $searchPage = ('body.search');
var $inputValue = $('#content-section #search #search-input').val();

if ($searchIcon) {
    $searchIcon.click(function () {
        $(this).parent('.nav-menu').children('#search').toggleClass('expand');
        $searchInput.focus();
    });
}

$searchInput.on('input', function () {
    if ($(this).val() != '') {
        $(this).parent('#search').children('button').addClass('show');
    } else {
        $(this).parent('#search').children('button').removeClass('show');
    }
});

function highlight($searchInput, $text) {
    var rgxp = new RegExp($searchInput, 'gi');
    var repl = '<span class="highlight">' + $searchInput + '</span>';
    $text.each(function (index, element) {
        $(this).html($(this).html().replace(rgxp, repl));
    });
}

if ($searchPage) {
    highlight($inputValue, $text);
    $searchInput.focus();
    $searchInput.val('');
    $searchInput.val($inputValue); 
}
