var $factsImage = $('.facts-image');
if ($factsImage.length > 0) {
    $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        var speedLargeCircle = 0.175;
        var speedSmallCircles = 0.075;

        $('.facts-image .outer-circle .inner-circle .large').each(function () {
            var $this = $(this);
            var parallaxSize = scroll * speedLargeCircle;

            $this.css({
                'transform': scroll > 0 ? ('translateY(-' + parallaxSize + 'px)') : ''
            });
        });

        $('.facts-image .outer-circle .inner-circle .small, .facts-image .outer-circle .inner-circle .medium').each(function () {
            var $this = $(this);
            var parallaxSize = scroll * speedSmallCircles;

            $this.css({
                'transform': scroll > 0 ? ('translateY(-' + parallaxSize + 'px)') : ''
            });
        });
    });
    var $number = $('.number').data('value');
    var $intNumber = parseInt($number.split(',').join(""), 10);
    $('.number').each(function () {
        $(this).prop('Counter', 0).animate({
            Counter: $intNumber
        }, {
            duration: 1000,
            easing: 'swing',
            step: function () {
                $(this).text(this.Counter.toLocaleString('en', { maximumSignificantDigits: 7 }))
            },
            complete: function () {
                $(this).text($number);
            },
        });
    });
}