import './_nav-menu';
import './_team';
import './_timeline';
import './_scroll-to';
import './_slideshow';
import './_search';
import './_feedback-form';
import './_archive';
import './_menu-scroll';
import './facts-image';

import { initMap } from "./_map.js";
window.initMap = initMap;

jQuery(function () {
    $("h1, h2, p, a").each(function () {
        $(this).html($(this).html().replace(/CO2/g, 'CO<sub>2</sub>'));
    });
    $(".press-release-link").each(function () {
        $(this).html($(this).html().replace(/CO\<sub\>2\<\/sub\>/g, 'CO2'));
    });
    $(".press-release-link h3, .press-release-link p").each(function () {
        $(this).html($(this).html().replace(/CO2/g, 'CO<sub>2</sub>'));
    });
});