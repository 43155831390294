var $menuButton = $('.main-nav .nav-menu #nav-icon');
var $header = $('header');
var $body = $('body');
var $contentSection = $('#content-section');
var $footer = $('footer');
var $menuContent = $('.main-nav .nav-menu .menu-content ul');
$menuContent.addClass('display-none');

function hideMenu() {
    $menuButton.removeClass('open');
    $('.main-nav .nav-menu .menu-content').css({
        'max-height': 0,
        'opacity': 0
    });
    if ($(window).width() <= 600) {
        $('.main-nav .nav-menu #search').css({
            'max-height': 0,
            'opacity': 0
        });
        $('.main-nav .nav-menu #search #search-input').css({
            'max-height': 0,
            'opacity': 0
        });
    }
    setTimeout(function () {
        $header.removeClass('show-nav');
    }, 400);
    $body.removeClass('no-scroll-y');
    setTimeout(function () {
        $menuContent.addClass('display-none');
        $menuContent.removeClass('anim');
        $('.main-nav .nav-menu .menu-content').removeClass('anim');
        $('.main-nav .nav-menu .menu-content .content-bottom').removeClass('anim');
    }, 500);
    setTimeout(function () {
        $contentSection.removeClass('blur-content');
    }, 200);
    setTimeout(function () {
        $footer.removeClass('blur-content');
    }, 200);
}

if ($menuButton) {
    $menuButton.click(function () {
        if ($menuButton.hasClass('open')) {
            hideMenu();
        } else {
            $('.main-nav .nav-menu .menu-content').addClass('anim');
            $('.main-nav .nav-menu .menu-content').css({
                'max-height': '100%',
                'opacity': 1
            });
            if ($(window).width() <= 600) {
                $('.main-nav .nav-menu #search').css({
                    'max-height': '50px',
                    'opacity': 1
                });
                $('.main-nav .nav-menu #search #search-input').css({
                    'max-height': '50px',
                    'opacity': 1
                });
            }
            $('.main-nav .nav-menu .menu-content .content-bottom').addClass('anim');
            $menuContent.addClass('anim');
            $menuButton.addClass('open');
            $header.addClass('show-nav');
            $body.addClass('no-scroll-y');
            $menuContent.removeClass('display-none');
            $contentSection.addClass('blur-content');
            $footer.addClass('blur-content');
        }
    });
}