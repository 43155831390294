jQuery('document').ready(function () {
    var $archive = $('.archive-block');
    var $mediaPagination, $mediaCount, $contentDiv, $mediaDisplayed, $numberOfPages;

    for (var i = 0; i < $archive.length; i++) {
        $contentDiv = $archive.eq(i).children('.content');

        if ($archive.eq(i).hasClass('archive-type-news')) {
            $mediaCount = $archive.children('.content').find('.news').children('article').length;
        }
        else {
            $mediaCount = $archive.eq(i).children('.content').children('.media').length;
        }

        if ($contentDiv.hasClass('columns-3')) {
            $mediaDisplayed = 6;
            $numberOfPages = Math.ceil($mediaCount / $mediaDisplayed);
        }
        else if ($contentDiv.hasClass('columns-4')) {
            $mediaDisplayed = 8;
            $numberOfPages = Math.ceil($mediaCount / $mediaDisplayed);
        }
        else if ($contentDiv.hasClass('columns-5')) {
            $mediaDisplayed = 10;
            $numberOfPages = Math.ceil($mediaCount / $mediaDisplayed);
        }
        else {
            $mediaDisplayed = 4;
            $numberOfPages = Math.ceil($mediaCount / $mediaDisplayed);
        }

        for (var j = 0; j < $mediaDisplayed; j++) {
            $contentDiv.children('.media').eq(j).addClass('show-media');
            $contentDiv.find('.news').children('article').eq(j).addClass('show-media');
        }
        if ($archive.eq(i).children('.content').children('.media-pagination').length > 0) {
            $mediaPagination = $archive.eq(i).children('.content').children('.media-pagination');
            for (var j = 1; j <= $numberOfPages; j++) {
                $mediaPagination.children('ul').append("<li><a href='#' class='page' data-displayed='" + $mediaDisplayed + "' data-index='" + j + "'>" + j + "</a></li>");
                jQuery("li:first-child a").addClass('current');
            }
        }
        if ($(window).width() > 900) {
            var $contentHeight = $contentDiv.innerHeight();
            $contentDiv.css({
                'min-height': $contentHeight
            });
        }

        $('.archive-block .media-pagination a').click(function (e) {
            e.preventDefault();
            $(this).parent('li').parent('ul').children('li').children('.page').removeClass('current');
            $(this).addClass('current');
            $(this).parent('li').parent('ul').parent('div').parent('.content').children('.media').removeClass('show-media');
            $(this).parent('li').parent('ul').parent('div').parent('.content').find('.news').children('article').removeClass('show-media');
            var $pageNumber = $(this).data('index');
            var $displayed = $(this).data('displayed');
            for (var j = (($pageNumber - 1) * $displayed); j < ($pageNumber * $displayed); j++) {
                $(this).parent('li').parent('ul').parent('div').parent('.content').children('.media').eq(j).addClass('show-media');
                $(this).parent('li').parent('ul').parent('div').parent('.content').find('.news').children('article').eq(j).addClass('show-media');
            }
        });
    }

});