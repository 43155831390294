const { htmlPrefilter } = require("jquery");

var $headerHeight = $('header').outerHeight();
var $heroHeight = $('.hero-text').outerHeight();
var $filterButton = $('.timeline-archive .filter-button');
var $filterDesc = $('.timeline-archive .filter-button #desc');
var $filterAsc = $('.timeline-archive .filter-button #asc');
var $svg = $('.timeline-archive .filter-button svg');

jQuery(function () {
    $filterButton.css({
        'top': $headerHeight + $heroHeight + 60
    });
    if(window.location.href.indexOf("?order=asc") > -1){
        $filterDesc.addClass('hide');
        $svg.addClass('rotate');
    }
    else {
        $filterDesc.removeClass('hide');
        $filterAsc.addClass('hide');
        $svg.removeClass('rotate');  
    }
});

$(window).resize(function () {
    var $headerHeight = $('header').outerHeight();
    var $heroHeight = $('.hero-text').outerHeight();
    $filterButton.css({
        'top': $headerHeight + $heroHeight + 60
    });
});

$filterDesc.click(function () {
    $filterDesc.css({
        'opacity': 0
    });
});