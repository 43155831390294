var $form = $('.feedback .wpcf7');
var $yesButton = $('.feedback #yes-button');
var $noButton = $('.feedback #no-button');

$yesButton.click(function(e) {
    e.preventDefault();
    if($("textarea[name='yes-feedback-message']").hasClass('show')) {
        $("textarea[name='yes-feedback-message']").removeClass('show');
        $form.removeClass('show');
        $yesButton.removeClass('active');
        $noButton.removeClass('hide');
    }
    else {
        $("textarea[name='yes-feedback-message']").addClass('show');
        $form.addClass('show');
        $yesButton.addClass('active');
        $noButton.addClass('hide');
    }
});

$noButton.click(function(e) {
    e.preventDefault();
    if($("textarea[name='no-feedback-message']").hasClass('show')) {
        $("textarea[name='no-feedback-message']").removeClass('show');
        $form.removeClass('show');
        $noButton.removeClass('active');
        $yesButton.removeClass('hide');
    }
    else {
        $("textarea[name='no-feedback-message']").addClass('show');
        $form.addClass('show');
        $noButton.addClass('active');
        $yesButton.addClass('hide');
    }
});