var $readMore = $('.team .persons .person .image .read-more');
var $readAbout = $('.team .persons .person .read-about');
var $underlay = $('.team .persons .person .info .underlay');
var $body = $('body');
var $exitButton = $('.exit-icon');
var $person = $('.team .persons .person');

function mobileClick() {
    $readAbout.click(function () {
        $underlay.toggleClass('show');
        $body.toggleClass('no-scroll-y');
        $(this).parent('.person').children('.info').children('.about').toggleClass('show-about');
    });

    $exitButton.click(function() {
        $underlay.removeClass('show');
        $body.removeClass('no-scroll-y');
        $(this).parent().parent().parent('.about').removeClass('show-about');
    });
}

function desktopClick() {
    $readMore.parent('.image').addClass('pointer');

    $readMore.parent('.image').click(function () {
        $underlay.toggleClass('show');
        $body.toggleClass('no-scroll-y');
        $(this).parent('.person').children('.info').children('.about').toggleClass('show-about');
        var $about = $('.team .persons .person .info .show-about');

        $about.click(function (event) {
            var $target = $(event.target);
            var $about = $(this);
            
            if ($target.is($about.children('.about-wrapper').children('.top-content').children('.image-wrapper')) || $target.is($about.children('.about-wrapper').children('.top-content').children('.image-wrapper').children('img')) || $target.is($about.children('.about-wrapper').children('.text-content'))) {
                return;
            }
            else {
                $about.removeClass('show-about');
                $underlay.removeClass('show');
                $body.removeClass('no-scroll-y');
            }
        });
    });
}

if ($readAbout && $(window).width() < 900) {
    $readAbout.parent('.person').addClass('has-about');
    $readAbout.closest('.image').addClass('pointer');

    mobileClick();
}
else if ($readMore && $(window).width() >= 900) {
    desktopClick();
}

$(window).resize(function () {
    if ($(window).width() >= 900) {
        $readAbout.css({
           'display': 'none' 
        });
        desktopClick();
    }
    if ($(window).width() < 900) {
        $readAbout.css({
           'display': 'flex' 
        });
        mobileClick();
    }
});